/* eslint-disable no-useless-escape */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import TextButton from '../TextButton/Loadable'
import Dropdown from '../Dropdown/Loadable'
import Button from '../Button/Loadable'
import TextInput from '../TextInput/Loadable'
import ModalOverlay from '../ModalOverlay/Loadable'
import ModalNotification from '../ModalNotification/Loadable'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import UserRowCardStyled from './styledComponent'

import MESSAGE from './message'
import colors from '../../colors'

const ADMIN = 'admin'
const MANAGER = 'manager'
const VIEWER = 'viewer'
const adminOptions = [
  { text: 'Administrator', value: ADMIN },
  { text: 'CPF Administrator', value: MANAGER },
  { text: 'Viewer', value: VIEWER }
]
const COLLABORATOR_AMOUNT = 5

class UserRowCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showDeleteUserModal: false,
      showUpdateUserModal: false,
      selectedProject: props.projectID,
      tooltipOpen: false
    }
  }

  getListProjectOptions() {
    const listAllProjectOptions = this.props.listProject.map((data) => {
      return {
        text: data.name,
        value: data.project_id
      }
    })
    return listAllProjectOptions
  }

  preparedSelectedProject() {
    const listAllProjectOptions = this.getListProjectOptions()
    let selectedProject = listAllProjectOptions.find((optionData) => optionData.value === this.props.projectID)
    if (this.props.isManager) {
      selectedProject = listAllProjectOptions.filter((optionData) => {
        const targetManager = this.props.listManager.find((managerData) => managerData.id === this.props.userID)
        const selected = targetManager.projects.find((projectData) => projectData.project_id === optionData.value)
        return !!selected
      })
    }
    return selectedProject
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getStatusSection() {
    const active = this.props.active ? 'active' : 'in-active'
    return (
      <div className="user-status-section-container">
        <div className={`status ${active}`} />
      </div>
    )
  }

  getUserDetailSection() {
    return <div className="user-detail-section-container prevent-text-overflow">{this.props.email}</div>
  }

  getTempHiddenInputAndCopy(registerURL) {
    const el = document.createElement('input')
    el.value = `${window.location.origin}/set-password/${registerURL}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  handleCopyLinkClicked = () => {
    const registerURL = this.props.registerURL.split('/')[4]
    this.handleTooltipOpen()
    this.getTempHiddenInputAndCopy(registerURL)
  }

  handleDetailBtnClicked = () => {
    this.setState({
      showUpdateUserModal: true,
      selectedProject: this.preparedSelectedProject()
    })
  }

  handleRemoveBtnClicked = () => {
    this.setState({
      showDeleteUserModal: true
    })
  }

  handleTooltipOpen = () => {
    this.setState({
      tooltipOpen: true
    })
  }

  handleTooltipClose = () => {
    this.setState({
      tooltipOpen: false
    })
  }

  getOptionSection() {
    const copyLinkBtn = (
      <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={this.handleTooltipClose}
            open={this.state.tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={this.getMessage('copy_text')}
          >
            <div>
              <TextButton id="copy-link" className="option-btn" label={this.getMessage('copy_link_txt')} onClick={this.handleCopyLinkClicked} />
            </div>
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
    const detailBtn = <TextButton className="option-btn" label={this.getMessage('detail_txt')} onClick={this.handleDetailBtnClicked} />
    const deleteBtn = <TextButton className="option-btn" label={this.getMessage('remove_txt')} onClick={this.handleRemoveBtnClicked} danger />
    return (
      <div className="option-section-container">
        {this.props.showCopyRegisterLinkBtn && !this.props.active ? copyLinkBtn : null}
        {this.props.showDetailBtn ? detailBtn : null}
        {this.props.showDeleteBtn ? deleteBtn : null}
      </div>
    )
  }

  handleCloseUpdateUserModal = () => {
    this.setState({
      showUpdateUserModal: false,
      selectedProject: this.props.projectID
    })
  }

  handleUpdateUserBtnClicked = () => {
    const selectedProject = this.state.selectedProject
    const userID = this.props.userID
    this.props.onUpdateUser(userID, selectedProject, this.props.projectID)
    this.handleCloseUpdateUserModal()
  }

  handleSelectedProjectChanged = (data) => {
    this.setState({
      selectedProject: this.props.isManager ? [...this.state.selectedProject, data] : data
    })
  }

  handleRemoveSelectedProject = (option) => {
    this.setState({
      selectedProject: this.state.selectedProject.filter((data) => data.value !== option.value)
    })
  }

  handleClearAllSelectedProject = () => {
    this.setState({
      selectedProject: []
    })
  }

  _isEmailValid(email) {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexEmail.test(email)
  }

  shouldDisabledEditUserBtn() {
    const isEmailValid = this._isEmailValid(this.props.email.trim())
    const isSelectedProjectValid = this.props.isManager ? this.state.selectedProject.length > 0 : this.state.selectedProject
    let isViewerAmountValid = true
    if (!this.props.isManager) {
      const selectedProjectData = this.props.listProject.find((projectData) => projectData.project_id === this.state.selectedProject.value)
      isViewerAmountValid = selectedProjectData ? selectedProjectData.users.length < COLLABORATOR_AMOUNT : false
    }
    return !(isEmailValid && isSelectedProjectValid && isViewerAmountValid)
  }

  getRoleDropdownContainer() {
    let output = null
    if (this.props.isManager) {
      output = (
        <div className="modal-user-management-row-container">
          <Dropdown
            className="role-dropdown"
            label="ROLE"
            labelPosition="top"
            options={adminOptions}
            value={MANAGER}
            language={this.props.language}
            buttonColor={colors.jadeGreen}
            disabled
          />
        </div>
      )
    }
    return output
  }

  preparedListProjectOptions() {
    const listAllProjectOptions = this.props.listProject.map((data) => {
      return {
        text: data.name,
        value: data.project_id
      }
    })
    let projectOptions = listAllProjectOptions
    if (this.props.isManager) {
      projectOptions = projectOptions.filter((data) => {
        const selected = this.state.selectedProject.find((obj) => obj.value === data.value)
        return !selected
      })
    }
    return projectOptions
  }

  getProjectDropdownContainer() {
    const listProjectOptions = this.preparedListProjectOptions()
    return (
      <div className="modal-user-management-row-container">
        <Dropdown
          className="project-dropdown"
          label="PROJECT"
          labelPosition="top"
          options={listProjectOptions}
          onChange={this.handleSelectedProjectChanged}
          onRemoveOption={this.handleRemoveSelectedProject}
          onClearAll={this.handleClearAllSelectedProject}
          value={this.state.selectedProject}
          language={this.props.language}
          buttonColor={colors.jadeGreen}
          multiSelect={this.props.isManager}
        />
      </div>
    )
  }

  getUpdateUserModal() {
    let output = null
    if (this.state.showUpdateUserModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseUpdateUserModal}>
          <div className="modal-user-management-container">
            <div className="modal-user-management-header-container">{this.getMessage('update_user_dialog_title_txt')}</div>
            <div className="modal-user-management-row-container">
              <TextInput
                id="user-detail-email"
                placeHolder={this.getMessage('update_user_dialog_email_placeholder')}
                label={this.getMessage('update_user_dialog_email_label')}
                value={this.props.email}
                mode="material-design"
                disabled
              />
            </div>
            {this.getRoleDropdownContainer()}
            {this.getProjectDropdownContainer()}
            <div className="modal-user-management-btn-container">
              <Button
                id="create-new-user-btn"
                className="modal-user-management-btn"
                onClick={this.handleUpdateUserBtnClicked}
                text={this.getMessage('update_user_dialog_save_btn')}
                disabled={this.shouldDisabledEditUserBtn()}
              />
            </div>
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  handleCloseRemoveUserModal = () => {
    this.setState({
      showDeleteUserModal: false
    })
  }

  handleDeleteBtnClicked = () => {
    this.props.onRemoveUser(this.props.userID)
    this.handleCloseRemoveUserModal()
  }

  getRemoveUserModal() {
    let output = null
    if (this.state.showDeleteUserModal) {
      output = (
        <ModalNotification
          className="remove-user-modal"
          title={this.getMessage('delete_dialog_title')}
          message={this.getMessage('delete_dialog_message')}
          onSecondaryButtonClick={this.handleCloseRemoveUserModal}
          onPrimaryButtonClick={this.handleDeleteBtnClicked}
          onOverLayClose={this.handleCloseRemoveUserModal}
          secondaryButtonText={this.getMessage('delete_cancel_btn')}
          primaryButtonText={this.getMessage('delete_confirm_btn')}
        />
      )
    }
    return output
  }

  render() {
    return (
      <UserRowCardStyled>
        {this.getStatusSection()}
        {this.getUserDetailSection()}
        {this.getOptionSection()}
        {this.getUpdateUserModal()}
        {this.getRemoveUserModal()}
      </UserRowCardStyled>
    )
  }
}

UserRowCard.defaultProps = {
  language: 'EN',
  active: false,
  registerURL: '',
  showCopyRegisterLinkBtn: false,
  showDetailBtn: false,
  showDeleteBtn: false,
  isManager: false
}

UserRowCard.propTypes = {
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isManager: PropTypes.bool,
  projectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  language: PropTypes.string,
  active: PropTypes.bool,
  email: PropTypes.string.isRequired,
  listProject: PropTypes.arrayOf(
    PropTypes.shape({
      project_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  registerURL: PropTypes.string,
  showCopyRegisterLinkBtn: PropTypes.bool,
  showDetailBtn: PropTypes.bool,
  showDeleteBtn: PropTypes.bool,
  onUpdateUser: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired
}

export default UserRowCard
