const MESSAGE = {
  EN: {
    copy_link_txt: 'Copy Link',
    copy_text: 'Copied!',
    detail_txt: 'Details',
    remove_txt: 'Remove',
    update_user_dialog_title_txt: 'User Details',
    update_user_dialog_email_label: 'EMAIL',
    update_user_dialog_email_placeholder: 'Enter User Email here',
    update_user_dialog_project_title: 'PROJECT',
    update_user_dialog_save_btn: 'Save',
    delete_dialog_title: 'Remove User',
    delete_dialog_message: 'Are you sure to remove user ?',
    delete_cancel_btn: 'Cancel',
    delete_confirm_btn: 'Remove',
    project: 'PROJECT'
  },
  TH: {
    copy_link_txt: 'คัดลอกลิ้งค์',
    copy_text: 'คัดลอกลิ้งค์แล้ว',
    detail_txt: 'ข้อมูล',
    remove_txt: 'ลบ',
    update_user_dialog_title_txt: 'ข้อมูลผู้ใช้',
    update_user_dialog_email_label: 'อีเมล',
    update_user_dialog_email_placeholder: 'ใส่อีเมลผู้ใช้ตรงนี้',
    update_user_dialog_project_title: 'โปรเจกต์',
    update_user_dialog_save_btn: 'บันทึก',
    delete_dialog_title: 'ลบผู้ใช้',
    delete_dialog_message: 'คุณต้องการที่จะลบผู้ใช้นี้หรือไม่',
    delete_cancel_btn: 'ยกเลิก',
    delete_confirm_btn: 'ลบ',
    project: 'โปรเจกต์'
  }
}

export default MESSAGE
