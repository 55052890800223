import styled from 'styled-components'

export default styled.div`
  display: flex;
  width: 100%;
  height: 45px;
  background: white;
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .user-status-section-container {
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    .status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      &.active {
        background: ${(props) => props.theme.onlineCamera};
      }
      &.in-active {
        background: ${(props) => props.theme.greyDisabled};
      }
    }
  }
  .user-detail-section-container {
    display: flex;
    align-items: center;
    width: 520px;
    font-family: 'Prompt', sans-serif;
    font-weight: bold;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1f2d39;
  }
  .option-section-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 595px;
    padding-right: 30px;
    .option-btn {
      font-size: 14px;
      margin-left: 30px;
    }
  }
`
